.p {
    width: 50%;
    margin-right: 100px;
}
.img {
    width: 150px;
    height: 200px;
    margin-top: 10px;
    margin-right: 100px;
}
@media only screen and (max-width: 920px) {
    .container {
        margin-right: 120px;
    }
}
