body {
    background-color: rgb(204, 224, 197);
    margin: 0; /* Remove default margin on the body */
    padding: 0; /* Remove default padding on the body */
}

.header {
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.item {
    border: 2px solid black;
    padding: 10px;
    width: 150px;
    font-size: 10px;
    border-radius: 5px;
    margin-right: 10px;
    text-align: center;
    color: black;
    margin-top: 25px;
    background-color: rgb(142, 186, 125);
}

.item:hover {
    background-color: black;
    color: white;
}

/* Media query for screens 820px or less */
@media only screen and (max-width: 920px) {
    .header {
        position: fixed;
        right: -25px;
        width: 180px;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2px;
        border-radius: 10px;
    }

    .item {
        width: 100px;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
