.h1 {
    padding: 30px;
}
.content {
    padding: 30px;
    font-size: 24px;
}
/* Media query for screens 820px or less */
@media only screen and (max-width: 920px) {
    .container {
        margin-right: 130px;
    }
    .h1 {
        padding: 5px;
    }
    .conetnt {
        padding: 10px;
        font-size: 12px;
        margin-right: 10px;
    }
}
